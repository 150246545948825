<template>
  <div id="app" class="appMain">
    <div class="appHead">
      <div class="logoBox" @click="toBigScreen">
        <img src="statics/img/logo.png" alt="">
      </div>
      <div class="loginInfo">
        <img :src="headImg" class="headImg">
        <div class="ddBox">
          <el-dropdown>
            <span class="el-dropdown-link">
              {{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item @click.native="changeUserModal = true">修改资料</el-dropdown-item> -->
              <el-dropdown-item @click.native="changePwdModal = true">修改密码</el-dropdown-item>
              <el-dropdown-item @click.native="logoutModal = true">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="appLeft">
      <el-menu :default-active="defActive" background-color="#545c64" text-color="#fff" :collapse="isCollapse" router
        active-text-color="#ffd04b">
        <el-submenu index="Home19" v-show="showPage['01']">
          <template slot="title">
            <i class="el-icon-s-tools"></i>
            <span>系统设置</span>
          </template>
          <el-menu-item index="/CreatorManage" v-show="showPage['0101']">主创人设置</el-menu-item>
          <el-menu-item index="/DictionaryManage" v-show="showPage['0102']">字典项管理</el-menu-item>
          <!-- <el-menu-item index="/SBGZGL">设备故障管理</el-menu-item>           -->
          <!-- <el-menu-item index="/JHGL">计划管理</el-menu-item>      -->
          <el-menu-item index="/SPJKGL" v-show="showPage['0103']">视频监控管理</el-menu-item>
          <el-menu-item index="/ProvisionalPlan" v-show="showPage['0104']">临时计划管理</el-menu-item>
          <el-menu-item index="/NoticeOfWork" v-show="showPage['0105']">上岗告知书</el-menu-item>
          <el-menu-item index="/projectDataFile" v-show="showPage['0106']">项目统计报表管理</el-menu-item>
        </el-submenu>
        <el-submenu index="Home12" v-show="showPage['02']">
          <template slot="title">
            <i class="el-icon-user-solid"></i>
            <span>用户管理</span>
          </template>
          <el-menu-item index="/FCLX" v-show="showPage['0206']">部门管理</el-menu-item>
          <el-menu-item index="/PostManage" v-show="showPage['0201']">岗位管理</el-menu-item>
          <el-menu-item index="/JobManage" v-show="showPage['0202']">职务管理</el-menu-item>
          <el-menu-item index="/UserManage" v-show="showPage['0203']">用户列表</el-menu-item>
          <el-menu-item index="/UserWorkLog" v-show="showPage['0207']">人员登机记录查询</el-menu-item>
          <el-menu-item index="/WorkTimeCount" v-show="showPage['0208']">工时统计</el-menu-item>
          <el-menu-item index="/LocatorManage" v-show="showPage['0204']">定位器管理</el-menu-item>
          <el-menu-item index="/UserPass" v-show="showPage['0205']">更换移动设备处理</el-menu-item>
        </el-submenu>
        <el-submenu index="Home22" v-show="showPage['03']">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>风场与设备</span>
          </template>
          <el-menu-item index="/windFieldManage" v-show="showPage['0301']">风场管理</el-menu-item>
          <el-menu-item index="/windFieldMachineType" v-show="showPage['0302']">风场设备类型管理</el-menu-item>
          <el-menu-item index="/FDJHSZ" v-show="showPage['0303']">发电计划设置</el-menu-item>
          <el-menu-item index="/ElectricPowerMachine" v-show="showPage['0304']">发电设备管理</el-menu-item>
          <el-menu-item index="/MachineRunManage" v-show="showPage['0305']">设备参数维护</el-menu-item>
          <el-menu-item index="/YearCostPlan" v-show="showPage['0306']">年度费用计划</el-menu-item>
          <el-menu-item index="/ExpensesManage" v-show="showPage['0307']">费用支出管理</el-menu-item>
          <el-menu-item index="/SYZFJ" v-show="showPage['0308']">升压站附件管理</el-menu-item>
        </el-submenu>
        <el-submenu index="Home9" v-show="showPage['04']">
          <template slot="title">
            <i class="el-icon-folder"></i>
            <span>资料库</span>
          </template>
          <!-- <el-menu-item index="/ZLKITEM?t=1">生产管理标准化制度库</el-menu-item>
                <el-menu-item index="/ZLKITEM?t=2">生产培训资料库</el-menu-item>
                <el-menu-item index="/ZLKITEM?t=3">生产档案管理资料库</el-menu-item>
                <el-menu-item index="/ZLKITEM?t=4">维斯塔斯维护手册维护资料库</el-menu-item>
                <el-menu-item index="/ZLKITEM?t=5">内外管理平台名称及登录密码资料库</el-menu-item> -->
          <el-menu-item index="/ZLKFL" v-show="showPage['0401']">资料库分类</el-menu-item>
          <el-menu-item index="/ZLKGL" v-show="showPage['0402']">资料库管理</el-menu-item>
          <el-menu-item index="/ZLKQXGL" v-show="showPage['0403']">资料库权限查看</el-menu-item>
        </el-submenu>
        <el-submenu index="Home14" v-show="showPage['05']">
          <template slot="title">
            <i class="el-icon-video-camera-solid"></i>
            <span>培训管理</span>
          </template>
          <el-menu-item index="/PXGL" v-show="showPage['0501']">生产人员日常培训</el-menu-item>
        </el-submenu>
        <el-submenu index="Home1">
          <template slot="title">
            <i class="el-icon-edit"></i>
            <span>考试管理</span>
          </template>
          <!-- <el-menu-item index="/KSA">安规考试</el-menu-item>
                <el-menu-item index="/KSB">“四种人”考试</el-menu-item>
                <el-menu-item index="/KSC">生产人员日常培训考试</el-menu-item>
                <el-menu-item index="/KSD">配件供应商维斯塔斯备品备件考试</el-menu-item> -->
          <el-menu-item index="/TJGL" v-show="showPage['0602']">题集管理</el-menu-item>
          <el-menu-item index="/SJGL" v-show="showPage['0601']">试卷管理</el-menu-item>
          <el-menu-item index="/KSGL" v-show="showPage['0603']">考试配置</el-menu-item>
          <el-menu-item index="/WDKS">我的考试</el-menu-item>
        </el-submenu>
        <!-- <el-submenu index="Home13">
                <template slot="title">
                  <i class="el-icon-s-tools"></i>
                  <span>考试成绩</span>
                </template>
                
               
            </el-submenu> -->


        <el-submenu index="Home11" v-show="showPage['07']">
          <template slot="title">
            <i class="el-icon-s-tools"></i>
            <span>设备巡检</span>
          </template>
          <el-menu-item index="/XJGL" v-show="showPage['0701']">巡检管理</el-menu-item>
          <el-menu-item index="/CheckResultManage" v-show="showPage['0702']">检查结果管理</el-menu-item>
          <el-menu-item index="/RWCX" v-show="showPage['0703']">任务查询</el-menu-item>
          <el-menu-item index="/RWLCCX" v-show="showPage['0704']">任务流程查询</el-menu-item>
          <el-menu-item index="/MKGL" v-show="showPage['0705']">模块管理</el-menu-item>
        </el-submenu>
        <el-submenu index="Home33" v-show="showPage['08']">
          <template slot="title">
            <i class="el-icon-s-check"></i>
            <span>流程管理</span>
          </template>
          <el-menu-item index="/WFFieldType" v-show="showPage['0801']">字段类型管理</el-menu-item>
          <el-menu-item index="/WFFormManage" v-show="showPage['0802']">表单管理</el-menu-item>
          <el-menu-item index="/WFFlowsheet" v-show="showPage['0803']">流程管理</el-menu-item>
          <!-- <el-menu-item index="/WFSerialNumber">文号管理</el-menu-item> -->
          <el-menu-item index="/WFOpinoin" v-show="showPage['0804']">审批意见管理</el-menu-item>
        </el-submenu>
        <el-submenu index="Home44">
          <template slot="title">
            <i class="el-icon-s-order"></i>
            <span>工作台</span>
          </template>
          <el-menu-item index="/WFCreateFlowsheet">起草流程</el-menu-item>
          <el-menu-item index="/WFFlowsheetLog">我发起的流程</el-menu-item>
          <el-menu-item index="/WFBackLog">我的待办</el-menu-item>
          <el-menu-item index="/WFReadLog">我的待阅</el-menu-item>
        </el-submenu>
        <el-submenu index="Home2" v-show="showPage['09']">
          <template slot="title">
            <i class="el-icon-date"></i>
            <span>定检管理</span>
          </template>
          <el-menu-item index="/DJRWCK" v-show="showPage['0901']">定检任务查看</el-menu-item>
          <el-menu-item index="/NDDJJH" v-show="showPage['0902']">年度定检计划</el-menu-item>
          <el-menu-item index="/RCDJJH" v-show="showPage['0903']">日常定检计划</el-menu-item>
          <el-menu-item index="/ZL" v-show="showPage['0904']">定检交底报告</el-menu-item>
          <el-menu-item index="/InspectionTemplate" v-show="showPage['0905']">定检模板</el-menu-item>
          <el-menu-item index="/WorkSplit" v-show="showPage['0906']">工作分解</el-menu-item>
          <!-- <el-menu-item index="/HYJL">定检会议记录</el-menu-item> -->
        </el-submenu>
        <el-submenu index="Home15" v-show="showPage['15']">
          <template slot="title">
            <i class="el-icon-s-home"></i>
            <span>仓库管理</span>
          </template>
          <el-menu-item index="/StoreManage" v-show="showPage['1501']">仓库列表</el-menu-item>
          <el-menu-item index="/RackManage" v-show="showPage['1502']">货架管理</el-menu-item>
          <el-menu-item index="/GoodsManage" v-show="showPage['1503']">物料管理</el-menu-item>
          <el-menu-item index="/MatterManage" v-show="showPage['1504']">领料单查看</el-menu-item>
          <el-menu-item index="/InOutManage" v-show="showPage['1505']">出入库记录</el-menu-item>
          <el-menu-item index="/LightManage" v-show="showPage['1506']">继电器管理</el-menu-item>
          <el-menu-item index="/VideoManage" v-show="showPage['1507']">监控管理</el-menu-item>
        </el-submenu>
        <el-submenu index="Home3" v-show="showPage['10']">
          <template slot="title">
            <i class="el-icon-set-up"></i>
            <span>地调及春秋检</span>
          </template>
          <el-menu-item index="/DDTD" v-show="showPage['1001']">预试地调管理</el-menu-item>
          <!-- <el-menu-item index="/NDYS">年度预试</el-menu-item> -->
          <el-menu-item index="/CQJ" v-show="showPage['1002']">春检秋检</el-menu-item>
          <!-- <el-menu-item index="/YJGL">监测应急管理</el-menu-item> -->
          <!-- <el-menu-item index="/LCJCWTJL">历次检查问题记录</el-menu-item> -->
        </el-submenu>
        <!-- <el-submenu index="Home4">
              <template slot="title">
                <i class="el-icon-document"></i>
                <span>部件检查</span>
              </template>
              <el-menu-item index="/DBJZXZDJC">大部件检测</el-menu-item>
              <el-menu-item index="/YLJCYGH">油类检查与更换</el-menu-item>
            </el-submenu> -->
        <el-submenu index="Home5" v-show="showPage['11']">
          <template slot="title">
            <i class="el-icon-search"></i>
            <span>技术监督管理</span>
          </template>
          <el-menu-item index="/DTJC" v-show="showPage['1101']">技术监督检查</el-menu-item>

          <!-- <el-menu-item index="/JSJDB">技术监督自查</el-menu-item>
              <el-menu-item index="/JSJDC">技术监督动态检查</el-menu-item> -->
          <el-menu-item index="/JSJDA" v-show="showPage['1102']">技术监督会议</el-menu-item>
          <!-- <el-menu-item indexs="/JSJDD">技术监督年终会议</el-menu-item> -->
          <!-- <el-menu-item indexs="/JSJDE" @click="noDataPage()">风机油脂检测</el-menu-item>
              <el-menu-item indexs="/JSJDF" @click="noDataPage()">防雷系统检测</el-menu-item>
              <el-menu-item indexs="/JSJDG" @click="noDataPage()">水质检测</el-menu-item>
              <el-menu-item indexs="/JSJDH" @click="noDataPage()">电器设备红外成像检查</el-menu-item> -->
        </el-submenu>
        <!-- <el-submenu index="Home6">
              <template slot="title">
                <i class="el-icon-document"></i>
                <span>电气设备检查</span>
              </template>
              <el-menu-item index="/DQSBJCJL">检查记录</el-menu-item>
            </el-submenu> -->
        <!-- <el-menu-item index="/DZBGZ"><i class="el-icon-menu"></i>党建工会管理</el-menu-item> -->
        <el-submenu index="Home8" v-show="showPage['12']">
          <template slot="title">
            <i class="el-icon-folder-opened"></i>
            <span>招投标/保险</span>
          </template>
          <el-menu-item index="/ContractManage" v-show="showPage['1201']">合同管理</el-menu-item>
          <!-- <el-menu-item index="/GYSGL" v-show="showPage['1202']">供应商管理</el-menu-item>
              <el-menu-item index="/WZHPFL" v-show="showPage['1203']">货品分类</el-menu-item>
              <el-menu-item index="/CKGL" v-show="showPage['1204']">仓库管理</el-menu-item> -->
          <el-menu-item index="/WXGL" v-show="showPage['1205']">维修记录管理</el-menu-item>
          <!-- <el-menu-item index="/KCGL" v-show="showPage['1206']">库存管理</el-menu-item> -->
          <!-- <el-menu-item index="/FCWZKC">风场物资库存</el-menu-item>
              <el-menu-item index="/GSWZKC">公司物资库存</el-menu-item> -->
          <!-- <el-menu-item index="/GHJGMXB" v-show="showPage['1207']">供货价格明细表</el-menu-item> -->
          <el-menu-item index="/QNYSJH" v-show="showPage['1208']">生产全年预算计划</el-menu-item>
          <el-menu-item index="/BXLP" v-show="showPage['1209']">保险理赔</el-menu-item>
          <el-menu-item index="/ZTBYW" v-show="showPage['1210']">生产招投标业务</el-menu-item>
        </el-submenu>
        <el-menu-item index="/XTPTZH" v-show="showPage['13']"><i
            class="el-icon-document-copy"></i>报表及汇总台账</el-menu-item>
        <el-menu-item index="/HQAQGL" v-show="showPage['14']"><i
            class="el-icon-chat-line-square"></i>日常会议后勤综合</el-menu-item>
      </el-menu>
    </div>
    <div class="appRight">
      <router-view></router-view>
    </div>
    <el-dialog title="操作提示" :visible.sync="logoutModal" width="30%">
      <span>确定退出登录？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="logoutModal = false">取 消</el-button>
        <el-button type="primary" @click="logoutSend">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改密码" :visible.sync="changePwdModal">
      <el-form :model="changePwd" status-icon :rules="pwdrules" ref="cpForm" label-width="100px" class="demo-cpForm">
        <el-form-item label="原密码" prop="oldPass">
          <el-input type="password" v-model="changePwd.oldPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPass">
          <el-input type="password" v-model="changePwd.newPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="changePwd.checkPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitPwdForm('cpForm')">提交</el-button>
          <el-button @click="resetPwdForm('cpForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    var validatePass0 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入原密码'));
      } else {
        callback();
      }
    };
    var validatePass1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else {
        if (this.changePwd.checkPass !== '') {
          this.$refs.cpForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.changePwd.newPass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      canDo:true,
      isCollapse: false,

      loadpage: false,
      defActive: "",
      userName: "",
      headImg: "statics/img/headImg.png",


      logoutModal: false,
      changePwdModal: false,
      changePwd: {
        oldPass: '',
        newPass: '',
        checkPass: '',
      },
      pwdrules: {
        oldPass: [
          { validator: validatePass0, trigger: 'blur' }
        ],
        newPass: [
          { validator: validatePass1, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
      },

      showPage: {
        "01": false,
        "0101": false,
        "0102": false,
        "0103": false,
        "0104": false,
        "0105": false,
        "0106": false,
        "02": false,
        "0201": false,
        "0202": false,
        "0203": false,
        "0204": false,
        "0205": false,
        "0206": false,
        "0207": false,
        "0208": false,
        "03": false,
        "0301": false,
        "0302": false,
        "0303": false,
        "0304": false,
        "0305": false,
        "0306": false,
        "0307": false,
        "0308": false,
        "04": false,
        "0401": false,
        "0402": false,
        "0403": false,
        "05": false,
        "0501": false,
        "06": false,
        "0601": false,
        "0602": false,
        "0603": false,
        "07": false,
        "0701": false,
        "0702": false,
        "0703": false,
        "0704": false,
        "0705": false,
        "08": false,
        "0801": false,
        "0802": false,
        "0803": false,
        "0804": false,
        "09": false,
        "0901": false,
        "0902": false,
        "0903": false,
        "0904": false,
        "0905": false,
        "0906": false,
        "10": false,
        "1001": false,
        "1002": false,
        "11": false,
        "1101": false,
        "1102": false,
        "12": false,
        "1201": false,
        "1202": false,
        "1203": false,
        "1204": false,
        "1205": false,
        "1206": false,
        "1207": false,
        "1208": false,
        "1209": false,
        "1210": false,
        "13": false,
        "1301": false,
        "1302": false,
        "1303": false,
        "1304": false,
        "14": false,
        "1401": false,
        "1402": false,
        "1403": false,
        "1404": false,
        "1405": false,
        "15": false,
        "1501": false,
        "1502": false,
        "1503": false,
        "1504": false,
        "1505": false,
        "1506": false,
        "1507": false,
      },
      meunStr: "",
    };
  },
  mounted() {
    this.loadItem = null
    this.getUserRoot()
  },
  methods: {
    getUserRoot() {
      // console.log(localStorage.getItem("fd_snowid"))
      // console.log(this.$store.state.guid)
      if (localStorage.getItem("fd_snowid") == this.$store.state.guid) {
        this.canDo = false
        this.showLoading("请稍后")
        var params = {
          UserSnowID: this.$store.state.guid
        }
        var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("RMenu/GetUserMenu", md5_data)
          .then((response) => {
            this.canDo = true
            this.hideLoading()
            if (response.data.SUCCESS) {
              var d = response.data.DATA
              if (d != "") {
                if (d == "*") {
                  for (var item in this.showPage) {
                    this.showPage[item] = true
                  }
                } else {
                  if (d.indexOf("9901") != -1) {
                    var listk = d.split(",")
                    for (var k = 0; k < listk.length; k++) {
                      var code = listk[k]
                      var pd = code.substring(0, 2)
                      this.showPage[pd] = true
                      this.showPage[code] = true
                    }
                  } else {
                    this.logout()
                  }
                }
                this.meunStr = d
                if (d != "*") {
                  this.canOpenPage(this.$route.name)
                } else {
                  this.isLoginPage(this.$route.name)
                }
              } else {
                this.logout()
              }
            }
          })
      } else {
        this.logout()
      }

    },
    canOpenPage(name) {
      var arouters = this.$router.getRoutes()
      var nowPageCode = ""
      for (var i = 0; i < arouters.length; i++) {
        if (arouters[i].name == name) {
          nowPageCode = arouters[i].props.default.code
          break;
        }
      }
      if (nowPageCode == "9999" || this.showPage[nowPageCode]) {
        this.isLoginPage(name)
      } else {
        //this.goToCanShowPage()
        this.$router.replace("/WFFlowsheetLog");
      }
    },
    goToCanShowPage() {
      if (this.meunStr == "") {
        this.$router.replace("/WFFlowsheetLog");
      } else {
        var arouters = this.$router.getRoutes()
        var gocode = ""
        for (var item in this.showPage) {
          if (this.showPage[item] && item.length > 2) {
            gocode = item
            break;
          }
        }
        if (gocode != "") {
          for (var i = 0; i < arouters.length; i++) {
            if (arouters[i].props.default.code == gocode) {
              this.$router.replace(arouters[i].path);
              break;
            }
          }
        } else {
          this.$router.replace("/WFFlowsheetLog");
        }
      }
    },
    isLoginPage(name) {
      this.userName = this.$store.state.name
      if (name == "CheckTempletItem" || name == "CheckTemplet" || name == "CheckTaskManage" || name == "TaskAllot") {
        this.defActive = '/XJGL'
      } else if (name == "SCanteen" || name == "CLGL" || name == "JYBXJL" || name == "YZSQSP" || name == "FYZCGL") {
        this.defActive = '/HQAQGL'
      } else if (name == "KHGL" || name == "KHX" || name == "KHXM" || name == "SCJL" || name == "GZZJJGZJH" || name == "KHLB" || name == "YZFA") {
        this.defActive = '/XTPTZH'
      } else if (name == "TDJH") {
        this.defActive = '/DDTD'
      } else {
        this.defActive = '/' + name
      }

      this.loadpage = true
    },
    //退出登录
    logoutSend() {
      this.logoutModal = false
      this.logout()
    },
    //修改密码
    submitPwdForm(formName) {
      if (this.canDo) {
        this.$refs.cpForm.validate((valid) => {
          if (valid) {
            this.sendChangePwd()
          } else {
            return false;
          }
        });
      }
    },
    sendChangePwd() {
      var params = {
        SNOWID: this.$store.state.guid,
        OLDPASSWORD: this.changePwd.oldPass,
        NEWPASSWORD: this.changePwd.newPass,
      }
      this.canDo = false
      this.showLoading("请稍后")
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("UserManage/UpdatePassword", md5_data)
        .then((response) => {
          this.canDo = true
          this.hideLoading()
          if (response.data.SUCCESS) {
            this.changePwdModal = false
            this.$message.success("已修改")

          } else {
            this.errorRequest(response.data.DATA, response.data.MSG, true)
          }
        })
        .catch((error) => {
          this.errorFlag(JSON.stringify(error))
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo = true
        });
    },
    resetPwdForm(formName) {
      this.$refs.cpForm.resetFields();
    },
    toBigScreen() {
      var url = window.location.host;
      if (this.meunStr == "*" || this.meunStr.indexOf("9902")) {
        window.localStorage.setItem("fd_token", this.$store.state.token);
        window.localStorage.setItem("fd_endtime", this.$store.state.endTime);
        window.localStorage.setItem("fd_snowid", this.$store.state.guid);
        window.localStorage.setItem("fd_loginname", this.$store.state.loginName);
        window.localStorage.setItem("fd_turename", this.$store.state.name);
        window.open("http://" + url + "/statics/bigScreen/index.html")
      } else {
        localStorage.removeItem("fd_token");
        localStorage.removeItem("fd_endtime");
        localStorage.removeItem("fd_snowid");
        localStorage.removeItem("fd_loginname");
        localStorage.removeItem("fd_turename");
        window.open("http://" + url + "/statics/bigScreen/login.html")
      }


    },
    noDataPage() {
      this.$message.error("暂未实现")
    }
  },
  computed: {
    //
  },
  watch: {
    '$route.name': function (newVal, oldVal) {
      this.isLoginPage(newVal)
    }
  },
};
</script>

<style>
.appMain {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.appHead {
  height: 56px;
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  background-color: #FFFFFF;
}

.appLeft {
  width: 240px;
  position: absolute;
  left: 0px;
  top: 56px;
  bottom: 0px;
  background-color: #545c64;
}

.appLeft .el-menu::-webkit-scrollbar {
  width: 0 !important
}

.appRight {
  position: absolute;
  left: 240px;
  bottom: 0px;
  right: 0px;
  top: 56px;
  overflow: auto;
  background-color: #F8F8F8;
  border-radius: 4px;
}



.logoBox {
  height: 55px;
  width: 240px;
  float: left;
  background-color: #545c64;
  overflow: hidden;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}

.logoBox img {
  width: 220px;
  display: block;
  float: left;
  margin-top: 1px;
  margin-left: 8px;
}

.loginInfo {
  overflow: hidden;
  height: 56px;
  position: absolute;
  right: 20px;
  top: 0;
}

.loginInfo .headImg {
  width: 36px;
  float: left;
  margin-top: 10px;
}

.appHead .headCon {
  height: 56px;
  float: left;
  overflow: hidden;
}

.appHead .headCon .btnItem {
  float: left;
  margin-right: 20px;
}

.loginInfo .ddBox {
  float: left;
  margin-left: 16px;
  cursor: pointer;
  margin-top: 15px;
}

.appMain .maxpage {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  padding: 0;
}

.el-menu {
  border-right: 0 !important;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

/* .el-menu-item:hover{background-color: #FFFFFF!important;} */


.el-dialog {
  overflow: hidden;
}

.pgItem {
  margin-bottom: 20px;
}

.taskItemBox .el-dialog {
  overflow: hidden;
}
</style>
